<template>
  <div class="about">
    <h1 align="center">This is an about page</h1>
  </div>
</template>

<script>
export default {
  name: 'About',

  components: {

  },
}
</script>
